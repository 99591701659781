import React from "react"
import { SanityProductList } from "typings/graphql"
import { ModeType } from "typings/modules"
import { ProductList, DisplayType } from "./ProductList"
import { LinkModeType } from "components/ProductItem/ProductItem"

export const SanityProductListBlock = ({
  fields,
}: {
  fields: SanityProductList
}) => (
  <ProductList
    mode={fields.theme as ModeType}
    displayType={fields.displayType as DisplayType}
    title={fields._rawTitle}
    linkMode={fields.linkMode as LinkModeType}
    backgroundColor={fields.backgroundColor.opacityHex}
    items={fields.items}
    internalName={fields.internalName}
  />
)

export default SanityProductListBlock
