import React, { useContext, useEffect, useState } from "react"
import { ThemeContext, ThemeProvider } from "styled-components"
import { useInView } from "react-intersection-observer"
import {
  Container,
  Title,
  ProductListGrid,
  ProductListCarousel,
  SlideContainer,
} from "./ProductList.styles"
import { useProductList } from "hooks/useProductList"
import { useProductPathPrefix } from "hooks/useProductPathPrefix"
import { LinkModeType, ProductItem } from "components/ProductItem/ProductItem"
import { PortableText } from "components/UI/PortableText/PortableText"
import { Carousel } from "components/UI/Carousel/Carousel"
import { tracking } from "utils/tracking"
import { ModeType } from "typings/modules"
import { SanityProductItem } from "typings/graphql"

export type DisplayType = "grid" | "cross"

export const ProductList = ({
  internalName,
  mode,
  displayType,
  title,
  linkMode,
  backgroundColor,
  items,
}: {
  internalName: string
  mode: ModeType
  displayType: DisplayType
  title: string
  linkMode: LinkModeType
  backgroundColor: string
  items: Array<SanityProductItem>
}) => {
  const [ref, inView] = useInView({ triggerOnce: true })
  const themeContext = useContext(ThemeContext)
  const [listViewed, setListViewed] = useState<boolean>(false)
  const { plItems, loading } = useProductList(items)
  const productPathPrefix = useProductPathPrefix()

  useEffect(() => {
    if (plItems?.length > 0 && !listViewed && !loading && inView) {
      tracking.productListViewed(
        internalName || title || "",
        plItems,
        productPathPrefix
      )
      setListViewed(true)
    }
  }, [title, plItems, loading, listViewed, inView])
  const tabletMobileGridDisplay = displayType === "cross" ? "none" : "grid"
  return (
    <ThemeProvider theme={{ localTheme: themeContext[mode] }}>
      <Container
        $bgColor={backgroundColor}
        base="1 [10] 1"
        md="2 [12] 2"
        lg="2 [20] 2"
        ref={ref}
      >
        {title && (
          <Title>
            <PortableText blocks={title} $mode={mode} />
          </Title>
        )}
        {displayType === "cross" && plItems?.length > 0 && (
          <ProductListCarousel>
            <Carousel plpCarousel>
              {plItems?.map((item, index) => (
                <SlideContainer key={index}>
                  <ProductItem
                    item={item}
                    linkMode={linkMode}
                    position={index}
                    mode={mode}
                  />
                </SlideContainer>
              ))}
            </Carousel>
          </ProductListCarousel>
        )}
        <ProductListGrid
          $display={tabletMobileGridDisplay}
          md="[6] 1 [6] 1 [6]"
        >
          {plItems?.map((item, index) => (
            <ProductItem
              key={index}
              item={item}
              linkMode={linkMode}
              position={index}
              mode={mode}
            />
          ))}
        </ProductListGrid>
      </Container>
    </ThemeProvider>
  )
}
