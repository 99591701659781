import { ProductItem } from "components/ProductItem/ProductItem"
import { Grid } from "components/UI/Grid/Grid"
import styled from "styled-components"
import { mq } from "styles"
import { $BgColor, $Display } from "typings/modules"

export const Container = styled(Grid)<$BgColor>`
  background: ${({ $bgColor }) => $bgColor};
  padding: 64px 0;
  ${mq.minWidth("md")} {
    padding: 80px 0;
  }
  ${mq.minWidth("lg")} {
    padding: 96px 0;
  }
`

export const Title = styled.div`
  color: ${({ theme }) => theme.localTheme.text?.primary.opacityHex};
  text-align: center;
  margin: 0 auto 32px;
  width: 83.33vw;
  ${mq.minWidth("md")} {
    margin: 0 auto 48px;
    width: 75vw;
  }
  ${mq.minWidth("lg")} {
    margin: 0 auto 64px;
    width: 41.66vw;
  }
`

export const ProductListGrid = styled(Grid)<$Display>`
  display: ${({ $display }) => $display};
  grid-row-gap: 48px;
  grid-column-gap: 8px;

  ${mq.minWidth("md")} {
    grid-row-gap: 64px;
  }

  ${mq.minWidth("lg")} {
    display: grid;
  }
`

export const ProductListProductItem = styled(ProductItem)``

export const ProductListCarousel = styled.div`
  margin: 0 -30px;
  ${mq.minWidth("md")} {
    margin: 0 -96px;
  }
  ${mq.minWidth("lg")} {
    display: none;
  }
`

export const SlideContainer = styled.div`
  outline: none;
  padding: 0 30px;
  ${mq.minWidth("md")} {
    padding: 0 96px;
  }
  ${mq.minWidth("lg")} {
    padding: 0;
  }
`
